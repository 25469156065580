import routes from "@/values/routes";

export default [
  {
    header: 'Menu',
  },
  {
    title: routes.home.title,
    route: routes.home.name,
    icon: 'HomeIcon',
  },
  {
    title: 'Catalogos',
    icon: 'LayersIcon',
    children: [
      {
        title: routes.area.title,
        route: routes.area.name,
      },
      {
        title: routes.profession.title,
        route: routes.profession.name,
      },
      {
        title: routes.category.title,
        route: routes.category.name,
      },
      {
        title: routes.service.title,
        route: routes.service.name,
      },
    ],
  },
]
