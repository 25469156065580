<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <b-overlay :show="loading" rounded="md" spinner-variant="primary" spinner-type="grow">
        <div class="content-body">
          <transition :name="routerTransition" mode="out-in">
            <slot />
          </transition>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BOverlay } from 'bootstrap-vue'
import { computed } from "@vue/composition-api";
import store from "@/store";

export default {
  components: {
    AppBreadcrumb, BOverlay
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    const loading = computed( () => store.state.app.showLoading )

    return {
      routerTransition, contentWidth, loading
    }
  },
}
</script>

<style>

</style>
